import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Snackbar, IconButton, Button
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
  
});

class Alert extends Component {
  render() {
    const { classes, open, onClose  } = this.props;

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={onClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{this.props.children}</span>}
        action={[
          <Button key="undo" color="secondary" size="small" onClick={onClose}>
            확인
          </Button>,
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    );
  }
}

export default withStyles(styles)(Alert);