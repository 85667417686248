import React from 'react';
// import { Provider } from 'react-redux';
// import { persistStore } from 'redux-persist';
// import { PersistGate } from 'redux-persist/integration/react';
// import store from './store';

import ReactDOM from 'react-dom';
import App from './App';

ReactDOM.render(
  // <Provider store={store}>
  //   <PersistGate loading={null} persistor={persistStore(store)}>
      <App />,
  //   </PersistGate>    
  // </Provider>, 
  document.getElementById('root'));