import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button
} from '@material-ui/core';

const styles = theme => ({
  dialog: {
    margin: '48px 24px'
  },

  text: {
    whiteSpace: 'pre-wrap'
  }
});

class PrivacyDialog extends Component {
  state = {
    scroll: 'paper',
    data: ''
  }

  componentDidMount = () => {
    fetch("/privacy.txt")
      .then(r => r.text())
      .then(data => {
        this.setState({ data });
      });
  };

  render() {
    const { classes, open, onClose } = this.props;

    return (
      <Dialog
        fullWidth
        classes={{
          paper: classes.dialog
        }}
        open={open}
        onClose={onClose}
        scroll={this.state.scroll}
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle id="scroll-dialog-title">개인 정보 수집/이용</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.text}>
            {this.state.data}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={onClose}>
            확인
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(PrivacyDialog);