import React, { Component } from 'react';
import { Link as RouteLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { 
  CssBaseline, Button, BottomNavigation, BottomNavigationAction, Typography, Link
} from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import qs from 'querystring';

import { BasicServer } from '../helpers';
import { Alert } from '../components';

const styles = theme => ({  
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(350 + theme.spacing.unit * 3 * 2)]: {
      width: 350,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  logo: {
    marginTop: 80,
    textAlign: "center",    
    position: 'relative',
    top: '10px',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    // marginTop: theme.spacing.unit * 2,
  },
  submit: {
    backgroundColor: '#14A2FF',
    marginTop: theme.spacing.unit,
    fontSize: 18,
    '&:hover': {
      backgroundColor: '#3882B9',
    }
  },
  footer: {
    backgroundColor: '#eee',
    width: '100%',
    position: 'fixed',
    bottom: '0'
  },
  poweredby: {
    marginTop: '20px',
    color: '#474747'
  },
  myhealth: {
    color: '#69a244',
    fontWeight: 'bold'
  }
});

class Login extends Component {
  state = {
    username: '',
    password: '',
    alert: false,
    alertMessage: ''
  };

  componentDidMount = () => {
    ValidatorForm.addValidationRule('validPassword', (value) => {
      const regex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{6,16}/; 
      
      return regex.test(value);
    });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleSubmit = async (e) => {
    // e.persist();
    const { username, password } = this.state;

    try {
      const result = await BasicServer.post('/login', qs.stringify({
        username,
        password
      }));

      console.log(result.data);

      if (result.data.transactionId) {
        this.props.history.push({
          pathname: '/authorize',
          state: result.data
        });
      } else if (result.data.redirect) {
        window.location = result.data.redirect;
      } else {
        console.log(`[Login] search: ${this.props.location.search}`); 

        this.props.history.push({
          pathname: '/authorize',
          state: this.props.location.state.search
        });
      }
    } catch (error) {
      console.log(error);
      const errorResp = error.response.data;
      this.setState({ 
        alertMessage: errorResp.message ? errorResp.message : '일시적인 오류가 발생하였습니다.'
      });      

      this.openAlert();
    }    
  }

  openAlert = () => {
    this.setState({ alert: true });
  };

  closeAlert = (event, reason) => {
    this.setState({ alert: false });
  };

  render() {
    const { classes } = this.props;
    const myhealthUrl = 'http://www.mediex.co.kr';

    return (
      <React.Fragment>        
        <main className={classes.main}>
          <CssBaseline />

          <div className={classes.logo}>
            <img src="/images/logo.png" alt="logo" width="250px" />
          </div>        

          <ValidatorForm
            className={classes.form}
            ref="form"
            onSubmit={this.handleSubmit}
          >
            <TextValidator
              className={classes.textField}
              variant="outlined"
              label="이메일"
              id="username"
              name="username"
              type="email"
              value={this.state.username}
              onChange={this.handleChange}
              autoComplete="email"
              placeholder="이메일"
              fullWidth
              margin="dense"              
              validators={['required', 'isEmail']}
              errorMessages={[
                '이메일을 입력해주세요', '이메일 형식이 올바르지 않습니다'
              ]}
            />

            <TextValidator 
              className={classes.textField}
              variant="outlined"
              label="비밀번호"
              id="password"
              name="password"
              type="password"
              value={this.state.password}
              onChange={this.handleChange}
              autoComplete="current-password"
              placeholder="비밀번호"
              fullWidth
              margin="dense"
              validators={['required', 'validPassword']}
              errorMessages={[
                '비밀번호를 입력해주세요', '비밀번호 형식이 올바르지 않습니다'
              ]}
            />

            <Button
              className={classes.submit}
              type="submit" 
              variant="contained" 
              color="secondary"
              size="large"
              fullWidth
            >
              로그인
            </Button> 

            <Typography className={classes.poweredby} variant="caption" align="right">
              Powered By
            </Typography>
            <Typography className={classes.myhealth} variant="body" align="right">
              <Link href={myhealthUrl} color="inherit" target="_blank" rel="noopener" className={classes.link}>
                myHealth
              </Link>
            </Typography>

            <Alert open={this.state.alert} onClose={this.closeAlert}>
              {this.state.alertMessage}
            </Alert>

          </ValidatorForm>

        </main>

        <BottomNavigation className={classes.footer} showLabels>        
          <BottomNavigationAction label="비밀번호 재설정" component={PasswordFindingLink} />
          <BottomNavigationAction label="회원가입" component={JoinLink} />
        </BottomNavigation>

      </React.Fragment>
    );
  }
}

const JoinLink = props => <RouteLink to="/join" {...props} />;
const PasswordFindingLink = props => <RouteLink to="/password-finding" {...props} />;

export default withStyles(styles)(Login);