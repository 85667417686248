import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { 
  Login, Join, PasswordFinding, Authorize
} from './pages';

class App extends Component {
  render() {
    return (
      <Router>                        
        <Switch>
          <Redirect exact from="/" to="/login" />          
          <Route path="/login" component={Login} />          
          <Route path="/join" component={Join} />
          <Route path="/password-finding" component={PasswordFinding} />
          <Route path="/authorize" component={Authorize} />
        </Switch>                
      </Router>
    );
  }
}

export default App;
