import React, { Component } from 'react';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  FormControlLabel, Checkbox, Button, FormGroup, 
} from '@material-ui/core';
import { 
  KeyboardArrowDown as DownIcon,
  KeyboardArrowUp as UpIcon
} from '@material-ui/icons';
import TermDialog from './TermDialog';
import PrivacyDialog from './PrivacyDialog';

const styles = theme => ({
  flex: {
    display: 'flex'
  },
  grow: {
    flexGrow: 1
  },
  agreeLabel: {
    marginRight: 0
  },
  formGroup: {
    backgroundColor: '#eee',
    paddingLeft: 25
  },
  dialog: {
    margin: '48px 24px'
  },
  checkbox: {
    paddingRight: 5
  },
});

class Agree extends Component {
  state = {
    agree: false,
    fold: true,    
    term: {
      agree: false,
      open: false
    },
    privacy: {
      agree: false,
      open: false
    },
  }

  handleFold = () => {
    this.setState({ fold: !this.state.fold });
  }

  handleCheckbox = type => (event) => {
    const { checked } = event.target;

    const defaultAgree = { agree: checked, open: false };

    if (type === 'all') {
      this.setState({ agree: checked });
      this.props.onUpdate(checked);
      
      this.setState({ term: defaultAgree })
      this.setState({ privacy: defaultAgree })
    } else {
      this.setState({ [type]: defaultAgree }, () => {
        if (this.state.term.agree && this.state.privacy.agree) {
          this.setState({ agree: true });
          this.props.onUpdate(true);          
        } else {
          this.setState({ agree: false });
          this.props.onUpdate(false);
        }
      });      
    }
  }

  handleDialogOpen = type => () => {
    this.setState({ [type]: { agree: this.state[type].agree, open: true, scroll: 'paper' } });
  };

  handleDialogClose = type => () => {
    this.setState({ [type]: { agree: this.state[type].agree, open: false, scroll: 'paper' } });
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <div className={classes.flex}>
          <FormControlLabel
            className={classNames(classes.grow, classes.agreeLabel)}
            control={
              <Checkbox 
                className={classes.checkbox}
                id="agree"
                name="agree"
                checked={this.state.agree}
                onChange={this.handleCheckbox('all')}
              />
            }
            label="이용 약관, 개인 정보 수집/이용 동의"                
          />
          <Button 
            className={classes.fold}
            variant="text" 
            size="small"
            onClick={this.handleFold}
          >
            {this.state.fold ? '펼치기' : '접기'}
            {this.state.fold ? (
              <DownIcon className={this.arrow} />
            ) : (
              <UpIcon className={this.arrow} />
            )}
            
          </Button>    
        </div>

        {!this.state.fold && (
          <FormGroup className={classes.formGroup}>
            <div className={classes.flex}>
              <FormControlLabel
                className={classes.grow}
                control={
                  <Checkbox              
                    className={classes.checkbox}    
                    id="term.agree"
                    name="agree"                        
                    checked={this.state.term.agree}
                    onChange={this.handleCheckbox('term')}
                  />
                }
                label="이용 약관"
              />
              <Button 
                className={classes.showButton}
                variant="text" 
                size="small"    
                onClick={this.handleDialogOpen('term')}            
              >
                보기
              </Button>
              <TermDialog
                open={this.state.term.open}
                onClose={this.handleDialogClose('term')}
              />
            </div>              
            
            <div className={classes.flex}>
              <FormControlLabel
                className={classes.grow}
                control={
                  <Checkbox               
                    className={classes.checkbox}   
                    id="privay.agree"
                    name="agree"
                    checked={this.state.privacy.agree}
                    onChange={this.handleCheckbox('privacy')}
                  />
                }
                label="개인 정보 수집/이용"
              />
              <Button 
                className={classes.showButton}
                variant="text" 
                size="small"              
                onClick={this.handleDialogOpen('privacy')}  
              >
                보기
              </Button>
              <PrivacyDialog
                open={this.state.privacy.open}
                onClose={this.handleDialogClose('privacy')}
              />
            </div>
            
          </FormGroup>
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Agree);