import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  CssBaseline, AppBar, Toolbar, Button, Typography
} from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import qs from 'querystring';

import { Alert } from '../components';
import { BasicServer } from '../helpers';

const styles = theme => ({
  toolbar: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(350 + theme.spacing.unit * 3 * 2)]: {
      width: 350,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  title: {
    marginTop: 20,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit * 2,
  },
  submit: {
    backgroundColor: '#14A2FF',
    marginTop: theme.spacing.unit,
    fontSize: 18,
    '&:hover': {
      backgroundColor: '#3882B9',
    }
  }
});

class PasswordFinding extends Component {
  state = {
    username: '',
    alert: false,
    alertMessage: ''
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleSubmit = async () => {
    const { username } = this.state;

    try {
      await BasicServer.post('/api/password/reset', qs.stringify({
        username
      }));

      this.setState({ alertMessage: `${username}(으)로 임시 비밀번호를 발송하였습니다.` })
      this.openAlert()      
    } catch (error) {
      const errorResp = error.response.data;
      this.setState({ 
        alertMessage: errorResp.message ? errorResp.message : '일시적인 오류가 발생하였습니다.'
      });      

      this.openAlert();
    }
  }

  openAlert = () => {
    this.setState({ alert: true });
  }

  closeAlert = (event, reason) => {
    this.setState({ alert: false });    

    this.props.history.push('/login');
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />

        <AppBar className={classes.bar} position="static" color="default" elevation={0}>
          <Toolbar className={classes.toolbar}>
            <img src="/images/logo.png" alt="logo" width="130px" />
          </Toolbar>
        </AppBar>

        <main className={classes.main}>
        
          <Typography className={classes.title} variant="subtitle1" align="center">
            아이디를 입력해 주세요.
          </Typography>

          <ValidatorForm
            className={classes.form}
            ref="form"
            onSubmit={this.handleSubmit}
            onError={errors => console.log(errors)}
          >
            <TextValidator
              className={classes.textField}
              variant="outlined"
              label="이메일"
              id="username"
              name="username"
              type="email"
              value={this.state.username}
              onChange={this.handleChange}
              autoComplete="email"
              placeholder="이메일 주소"
              fullWidth
              margin="dense"              
              validators={['required', 'isEmail']}
              errorMessages={[
                '이메일을 입력해주세요', '이메일 형식이 올바르지 않습니다'
              ]}
            />
          <Button
              className={classes.submit}
              type="submit" 
              variant="contained" 
              color="secondary"
              size="large"
              fullWidth
            >
              확인
            </Button> 

          </ValidatorForm>
        </main>      

        <Alert open={this.state.alert} onClose={this.closeAlert}>
          {this.state.alertMessage}
        </Alert>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(PasswordFinding);