import axios from 'axios';
import https from 'https';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_AUTH_URL}`
});

instance.defaults.httpsAgent = new https.Agent({ rejectUnauthorized: false });

instance.defaults.auth = {
  username: `${process.env.REACT_APP_CLIENT_ID}`,
  password: `${process.env.REACT_APP_CLIENT_SECRET}`
};

export default instance;