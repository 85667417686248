import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  CssBaseline, AppBar, Toolbar, Button, Typography
} from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Redirect } from 'react-router-dom';
import { Agree, Alert } from '../components';
import { BasicServer } from '../helpers';

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  toolbar: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(350 + theme.spacing.unit * 3 * 2)]: {
      width: 350,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  title: {
    marginTop: 20,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit * 2,
  },
  submit: {
    backgroundColor: '#14A2FF',
    marginTop: theme.spacing.unit,
    fontSize: 18,
    '&:hover': {
      backgroundColor: '#3882B9',
    }
  },
});

class Join extends Component {
  state = {
    username: '',
    password: '',
    confirmPassword: '',
    roles: [ 'user', 'manager' ],
    agree: false,
    alert: false,
    alertMessage: '',
    success: false
  };

  componentDidMount = () => {
    ValidatorForm.addValidationRule('isEmailUnique', async (value) => {
      if (value.indexOf('@') > 0) {
        try {
          const result = await BasicServer.head(`/api/identity/${value}`);
          return (result.status === 200 ? false : true);
        } catch (error) {
          return (error.response.status === 404 ? true : false);
        }
      } else {
        return false;
      }
    });

    ValidatorForm.addValidationRule('validPassword', (value) => {
      const regex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{6,16}/; 
      
      return regex.test(value);
    });

    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      return value === this.state.password;
    });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleSubmit = async () => {
    if (!this.state.agree) {
      this.setState({ alertMessage: '약관과 개인 정보 수집/이용에 동의해 주세요.' })
      this.openAlert()
    } else {
      try {
        await BasicServer.post('/api/users', this.state);

        this.props.history.push('/login');
      } catch (error) {
        const errorResp = error.response.data;
        this.setState({ 
          alertMessage: errorResp.message ? errorResp.message : '일시적인 오류가 발생하였습니다.'
        });      

        this.openAlert();
      }      
    }
  }

  openAlert = () => {
    this.setState({ alert: true });
  }

  closeAlert = (event, reason) => {
    this.setState({ alert: false });
  };

  handleAgree = (checked) => {
    this.setState({ agree: checked });
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />

        {this.state.success ? (
          <Redirect to="/" />
        ) : (
          <React.Fragment>
            <AppBar className={classes.bar} position="static" color="default">
              <Toolbar className={classes.toolbar}>
                <img src="/images/logo.png" alt="logo" width="130px" />
              </Toolbar>
            </AppBar>

            <main className={classes.main}>
            
              <Typography className={classes.title} variant="subtitle1" align="center">
                가입을 환영합니다.
              </Typography>

              <ValidatorForm
                className={classes.form}
                ref="form"
                onSubmit={this.handleSubmit}
              >
                <TextValidator
                  className={classes.textField}
                  variant="outlined"
                  label="이메일"
                  id="username"
                  name="username"
                  type="email"
                  value={this.state.username}
                  onChange={this.handleChange}
                  autoComplete="email"
                  placeholder="이메일 주소"
                  fullWidth
                  margin="dense"              
                  validators={['required', 'isEmail', 'isEmailUnique']}
                  errorMessages={[
                    '이메일을 입력해주세요', '이메일 형식이 올바르지 않습니다', '이미 사용중인 이메일입니다'
                  ]}
                />

                <TextValidator 
                  className={classes.textField}
                  variant="outlined"
                  label="비밀번호"
                  id="password"
                  name="password"
                  type="password"
                  value={this.state.password}
                  onChange={this.handleChange}
                  autoComplete="current-password"
                  placeholder="비밀번호(영문,숫자,특수문자 8-15자)"
                  fullWidth
                  margin="dense"
                  validators={['required', 'validPassword']}
                  errorMessages={[
                    '비밀번호를 입력해주세요', '비밀번호 형식이 올바르지 않습니다'
                  ]}
                />

                <TextValidator 
                  className={classes.textField}
                  variant="outlined"
                  label="비밀번호 재확인"
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  value={this.state.confirmPassword}
                  onChange={this.handleChange}
                  placeholder="비밀번호 재확인"
                  fullWidth
                  margin="dense"
                  validators={['required', 'validPassword', 'isPasswordMatch']}
                  errorMessages={[
                    '비밀번호를 입력해주세요', '비밀번호 형식이 올바르지 않습니다', '비밀번호가 일치하지 않습니다'
                  ]}
                />

                <Agree onUpdate={this.handleAgree} />

                <Button
                  className={classes.submit}
                  type="submit" 
                  variant="contained" 
                  color="secondary"
                  size="large"
                  fullWidth
                >
                  회원가입
                </Button> 

                <Alert open={this.state.alert} onClose={this.closeAlert}>
                  {this.state.alertMessage}
                </Alert>

              </ValidatorForm>
            </main>      
          </React.Fragment>
        )}
        
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Join);