import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  CssBaseline, AppBar, Toolbar, Button, Typography,
  Card, CardHeader, CardContent
} from '@material-ui/core';
import qs from 'querystring';

import { Redirect } from 'react-router-dom';
import { Alert } from '../components';
import { BasicServer } from '../helpers';

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  toolbar: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(350 + theme.spacing.unit * 3 * 2)]: {
      width: 350,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  card: {
    marginTop: theme.spacing.unit * 3
  },
  body: {
    marginBottom: theme.spacing.unit
  },
  appName: {
    color: '#009688',
    fontWeight: 'bold'
  },
  username: {
    color: '#2196f3'
  },
  caption: {
    marginTop: theme.spacing.unit * 4
  },
  submit: {
    backgroundColor: '#14A2FF',
    marginTop: theme.spacing.unit,
    fontSize: 18,
    '&:hover': {
      backgroundColor: '#3882B9',
    }
  },
});

class Authorize extends Component {
  state = {
    transactionId: '',
    clientName: '',
    username: '',
    search: '',
    alert: false,
    alertMessage: ''
  };

  componentDidMount = async () => {
    console.log(`[Authorize] search: ${this.props.location.search}`);  

    const propsState = this.props.location.state;        
    if (propsState && propsState.transactionId) {
      const { transactionId, clientName, username } = this.props.location.state;

      this.setState({
        transactionId, 
        clientName, 
        username
      });
    } else {
      const { search } = this.props.location;

      let authorizeUrl = '';
      if (search && search !== '') {
        authorizeUrl = `/oauth/authorize${search.replace(/%20/g, '')}&returnJSON=true`;        
      } else {
        authorizeUrl = `/oauth/authorize${propsState.search.replace(/%20/g, '')}&returnJSON=true`;
      }

      const result = await BasicServer.get(authorizeUrl);

      if (result.data.transactionId) {
        this.setState({
          transactionId: result.data.transactionId, 
          clientName: result.data.clientName, 
          username: result.data.username
        });
      } else if (result.data.redirect) {
        window.location = result.data.redirect;
      } else {
        this.props.history.push({
          pathname: '/login',
          state: {
            search: search.replace(/%20/g, '')
          }
        });
      }
    }
  }

  handleSubmit = async () => {
    const { transactionId } = this.state;

    try {
      const result = await BasicServer.post('/oauth/authorize/decision', qs.stringify({
          transaction_id: transactionId
      }));

      if (result.data.redirect) {
        window.location = result.data.redirect;
      }
    } catch (error) {
      const errorResp = error.response.data;
      this.setState({ 
        alertMessage: errorResp.message ? errorResp.message : '일시적인 오류가 발생하였습니다.'
      });      

      this.openAlert();
    }      
  }

  openAlert = () => {
    this.setState({ alert: true });
  }

  closeAlert = (event, reason) => {
    this.setState({ alert: false });
  };

  render() {
    const { classes } = this.props;
    const { clientName, username } = this.state;

    if (!clientName) {
      return (<React.Fragment></React.Fragment>);
    }

    return (
      <React.Fragment>
        <CssBaseline />

        <AppBar className={classes.bar} position="static" color="default">
          <Toolbar className={classes.toolbar}>
            <img src="/images/logo.png" alt="logo" width="130px" />
          </Toolbar>
        </AppBar>

        <main className={classes.main}>

          <Card className={classes.card}>
            <CardHeader 
              title={clientName}
              titleTypographyProps={{ align: 'center' }}
              className={classes.cardHeader}
            />
            <CardContent>
              <Typography className={classes.body} variant="subtitle2">
                <span className={classes.appName}>{clientName}</span>에서 <span className={classes.username}>{username}</span>님의 이메일, 건강데이터를 받습니다.
              </Typography>
              <Typography component="li" variant="body2" color="textSecondary" align="center">
                제공된 정보는 서비스 내 이용자 식별, 회원관리 및 서비스 제공을 위해 개인정보를 제공합니다.
              </Typography>
              <Typography className={classes.caption} component="li" variant="caption" align="center">
                동의 후에는 해당 서비스의 이용약관 및 개인정보취급방침에 따라 정보가 관리됩니다.
              </Typography>
            </CardContent>
          </Card>          

          <Button
            className={classes.submit}
            type="submit" 
            variant="contained" 
            color="secondary"
            size="large"
            fullWidth
            onClick={this.handleSubmit}
          >
            동의하기
          </Button> 

          <Alert open={this.state.alert} onClose={this.closeAlert}>
            {this.state.alertMessage}
          </Alert>

        </main>      
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Authorize);